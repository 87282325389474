class AjaxLoad {
    constructor (uri, options) {
        const settings = {
            requestMethod: 'GET',
            requestData: null,
            onError: null,
            onSuccess: null,
            addXRequestedWith: true // Control the addition of the X-Requested-With header
        };

        this.uri = uri;

        this.settings = Object.assign({}, settings, options);
        this.initialize();
    }

    initialize () {
        const xhr = new XMLHttpRequest();

        xhr.open(this.settings.requestMethod, this.uri, true);

        // Conditionally add the X-Requested-With header
        if (this.settings.addXRequestedWith) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        }

        xhr.onload = () => {
            const result = xhr.responseText;

            if (xhr.status >= 200 && xhr.status < 400) {
                // Success!
                if (typeof this.settings.onSuccess === 'function') {
                    this.settings.onSuccess(result);
                }
            } else {
                if (typeof this.settings.onError === 'function') {
                    this.settings.onError(result);
                }
            }
        };

        xhr.send(this.settings.requestData);
    }
}

export default AjaxLoad;

import './studio-finder.scss';
import MarkerClusterer from '@google/markerclustererplus/dist/markerclustererplus.umd';
import { createElementFromHTML, smoothScrollTo, tryParseJSON } from '../../utilities/js/helper';
import AjaxLoad from '../../utilities/js/ajax-load';

class StudioFinder {
    constructor ($map, defaults) {
        this.mapContainer = $map;

        this.map = { };
        this.mapReady = false;
        this.mapStyle = [ {featureType: 'administrative', 'elementType': 'labels.text.fill', 'stylers': [{'color': '#444444'}]}, {'featureType': 'administrative.country', 'elementType': 'all', 'stylers': [{'visibility': 'on'}]}, {'featureType': 'administrative.country', 'elementType': 'geometry.stroke', 'stylers': [{'lightness': '0'}, {'weight': '0.50'}]}, {'featureType': 'administrative.country', 'elementType': 'labels', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'landscape', 'elementType': 'all', 'stylers': [{'color': '#f2f2f2'}]}, {'featureType': 'poi', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'road', 'elementType': 'all', 'stylers': [{'saturation': -100}, {'lightness': 45}]}, {'featureType': 'road', 'elementType': 'labels', 'stylers': [{'visibility': 'on'}]}, {'featureType': 'road.highway', 'elementType': 'all', 'stylers': [{'visibility': 'simplified'}]}, {'featureType': 'road.highway', 'elementType': 'geometry', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'road.highway', 'elementType': 'labels', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'road.arterial', 'elementType': 'labels.icon', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'transit', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'transit.station', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'water', 'elementType': 'all', 'stylers': [{'color': '#46bcec'}, {'visibility': 'on'}]}, {'featureType': 'water', 'elementType': 'geometry.fill', 'stylers': [{'saturation': '2'}, {'color': '#dadada'}]}]; //  eslint-disable-line

        if (!defaults) {
            defaults = {
                lat: '47.3627982',
                lng: '13.682753'
            };
        }

        this.mapDefaultProperties = {
            container: null,
            center: new google.maps.LatLng(defaults.lat, defaults.lng), //  eslint-disable-line
            zoom: 4,
            minZoom: 1,
            maxZoom: 30,
            resetZoom: 5,
            zoomIn: 20,
            zoomOut: 4,
            panControl: false,
            zoomControl: true,
            scrollwheel: false,
            draggable: true,
            clustering: true,
            overviewMapControl: false,
            rotateControl: false,
            styles: this.mapStyle,
            autocomplete: [],
            autocompleteInput: [],
            autocompleteLastKey: null,
            autocompleteBoundsMarker: null,
            places: null,
            country: 'Deutschland',
            countryRestrict: { country: ['DE', 'AT'] },
            geolocation: null,
            setAlternativeGeolocation: true,
            disableDoubleClickZoom: false,
            disableDefaultUI: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP  //  eslint-disable-line
        };

        this.mapProperties = Object.assign({}, this.mapDefaultProperties, defaults);

        // RADIUS
        this.radius = null;
        this.radiusSteps = [];
        this.radiusValue = null;
        this.circle = null;

        if (this.mapContainer) {
            this.useDirections = (this.mapContainer.getAttribute('data-directions') !== null);
        }
        this.directionsService = null;
        this.directionsDisplay = null;

        this.markers = [];
        this.markerImages = [];
        this.mapMarker = [];
        this.activeMarker = [];
        this.boundsStudios = [];
        this.activeStudios = [];
        this.markerCluster = [];
        this.form = document.querySelectorAll('[data-map-form]');
        this.formSubmit = [];
        this.yourlocationSet = false;

        this.studioFormSubmitEvent = new CustomEvent('submit', { bubbles: true, cancelable: true });

        for (let formIndex = 0; formIndex < this.form.length; formIndex++) {
            this.formSubmit[formIndex] = this.form[formIndex].querySelector('[data-form-submit]');
        }

        this.debug = false;

        if (this.mapContainer) {
            this.icons = [{
                url: this.mapContainer.getAttribute('data-mapicon-location'),
                size: [15, 20],
                origin: [0, 0],
                anchor: [(15 / 2), [20]]
            }, {
                url: this.mapContainer.getAttribute('data-mapicon-cluster'),
                size: [30, 40],
                origin: [0, 0],
                anchor: [(30 / 2), [20]]
            }, {
                url: this.mapContainer.getAttribute('data-mapicon-mylocation'),
                size: [30, 40],
                origin: [0, 0],
                anchor: [(30 / 2), [(40 / 2)]]
            }, {
                url: this.mapContainer.getAttribute('data-mapicon-location-nofocus'),
                size: [15, 20],
                origin: [0, 0],
                anchor: [(15 / 2), [20]]
            }, {
                url: this.mapContainer.getAttribute('data-mapicon-cluster-nofocus'),
                size: [30, 40],
                origin: [0, 0],
                anchor: [(30 / 2), [20]]
            }];
        }

        this.activeInfoBoxMarker = null;

        this.preDefinedLocations = document.querySelectorAll('[data-pos-value]');
        this.geocoder = new google.maps.Geocoder(); //  eslint-disable-line

        this.oldBrowser = null;

        this.oldBrowser = document.querySelector('html').classList.contains('ie10') || document.querySelector('html').classList.contains('ie11');

        if (this.mapContainer) {
            this.init();
        } else {
            this.initAutoComplete();
            this.events();
        }
    }

    initAutoComplete () {
        this.mapProperties.autocompleteInput = document.querySelectorAll('[data-autocomplete]');

        // AutoComplete Input Elements
        if (this.mapProperties.autocompleteInput.length > 0) {
            for (let inputIndex = 0; inputIndex < this.mapProperties.autocompleteInput.length; inputIndex++) {
                this.autoComplete(inputIndex);
            }
        }
    }

    init () {
        if (typeof activeStudios !== 'undefined') { //  eslint-disable-line
            if (activeStudios.length > 0) { //  eslint-disable-line
                this.activeStudios = JSON.parse(activeStudios); //  eslint-disable-line
            }
        }

        // GET MARKERS
        if (typeof studios !== 'undefined') { //  eslint-disable-line
            this.markers = JSON.parse(studios); //  eslint-disable-line

            if (this.markers.length <= 1 || this.activeStudios.length > 0) {
                this.mapProperties.clustering = false;
            }
        } else {
            console.warn('no Studios where found!!');
        }

        if (typeof boundsStudios !== 'undefined') { //  eslint-disable-line
            if (boundsStudios.length > 0) { //  eslint-disable-line
                this.boundsStudios = JSON.parse(boundsStudios); //  eslint-disable-line
            } else {
                this.boundsStudios = JSON.parse(studios); //  eslint-disable-line
            }
        } else {
            this.boundsStudios = JSON.parse(studios); //  eslint-disable-line
        }

        // GET POSSIBLE RADIUS DATA
        this.radius = this.mapProperties.container.querySelector('.studio-finder__radius');

        if (this.radius) {
            this.radiusSteps = JSON.parse(this.radius.getAttribute('data-radius'));
            this.radiusValue = this.radius.querySelector('.radius__value').firstElementChild;
        }

        // INIT MAP
        this.map = new google.maps.Map(this.mapContainer, this.mapProperties); //  eslint-disable-line

        // SET MARKER IMAGES
        if (this.icons.length > 0) {
            for (let iconIndex = 0; iconIndex < this.icons.length; iconIndex++) {
                this.markerImages[iconIndex] = {
                    url: this.icons[iconIndex].url,
                    anchor: new google.maps.Point(parseInt(this.icons[iconIndex].anchor[0]), parseInt(this.icons[iconIndex].anchor[1])), //  eslint-disable-line
                    size: new google.maps.Size(parseInt(this.icons[iconIndex].size[0]), parseInt(this.icons[iconIndex].size[1])), //  eslint-disable-line
                    origin: new google.maps.Point(parseInt(this.icons[iconIndex].origin[0]), parseInt(this.icons[iconIndex].origin[1])), //  eslint-disable-line
                    scaledSize: new google.maps.Size(parseInt(this.icons[iconIndex].size[0]), parseInt(this.icons[iconIndex].size[1])), //  eslint-disable-line
                    //  optimized: false
                };
            }
        }

        if (typeof searchData === 'undefined') {
            this.yourlocationSet = true;
        }

        // ADD MARKERS TO THE MAP
        for (let markerIndex = 0; markerIndex < this.markers.length; markerIndex++) {
            this.addMarker(this.markers[markerIndex]);
        }

        if (this.markers.length === 1) {
            this.changeMarker('on', this.mapMarker[0]);
        }

        // Clustering all Markers
        if (this.mapProperties.clustering) {
            this.setCluster(this.icons[1].url);
        }

        // If Map got NO PRE-DEFINED Data
        if (typeof searchData === 'undefined') { // eslint-disable-line
            if (this.activeStudios.length <= 0) {
                // SET BOUNDS OF ALL MARKERS
                this.setBounds(this.boundsStudios);
            }
        } else {
            document.querySelector('.site-header__bar').setAttribute('data-rotate', 'back');
            const $studioTeaserForm = document.querySelector('div[data-ajaxform].form').firstElementChild;
            const $location = $studioTeaserForm.querySelector('input[name="location"]');
            if ($location) {
                if ($location.value !== '') {
                    document.querySelectorAll('input[name="studio_finder[location]"]').forEach((input) => {
                        input.value = $location.value;
                    });
                }
            }

            this.processUserData(JSON.parse(searchData)); // eslint-disable-line
        }

        if (this.mapContainer.getAttribute('data-markerpos-left') !== null) {
            this.moveMap(window.innerWidth / 4, 0);
        }

        if (this.activeStudios.length > 0) {
            this.resetMap();
            this.activateAndFitMarker(this.activeStudios, (activeMarkers) => {
                this.setBounds(activeMarkers, () => {
                    setTimeout(() => {
                        this.map.setZoom(this.map.getZoom() - 1);
                    }, 500);
                });
            });
        }

        // SET EVENTS
        this.events();
        this.initAutoComplete();
    }

    addMarker (data) {
        const icon = this.markerImages[0];
        const infoboxOptions = {
            content: null,
            disableAutoPan: true,
            maxWidth: 0,
            pixelOffset: new google.maps.Size(0, 0),  //  eslint-disable-line
            zIndex: null,
            //  closeBoxMargin: '10px 2px 2px 2px',
            closeBoxURL: '',
            infoBoxClearance: new google.maps.Size(1, 1),  //  eslint-disable-line
            isHidden: false,
            pane: 'floatPane',
            enableEventPropagation: false
        };

        let currentMarker = new google.maps.Marker({ //  eslint-disable-line
            position: new google.maps.LatLng(data.lat, data.lng), //  eslint-disable-line
            lat: data.lat,
            lng: data.lng,
            map: this.map,
            icon: icon || this.markerImages[0],
            company: data.companyAddon,
            street: data.street,
            zipcode: data.zipCode,
            city: data.city,
            id: data.id,
            infoBox: null
            //  animation: google.maps.Animation.DROP
        });

        this.mapMarker.push(currentMarker);

        /*************************************************/
        // CREATE INFOWINDOW
        currentMarker.infoWindowContent = '<div class="gmap__overlay"><div class="gmap__overlay__container"><a class="gmap__overlay__close icon-close"><i></i></a>';
        if (typeof data.companyAddon !== 'undefined') {
            currentMarker.infoWindowContent += '<h3>' + data.companyAddon + '</h3>';
        }

        if (typeof data.street !== 'undefined') {
            currentMarker.infoWindowContent += '<p>' + data.street;
            if (typeof data.city !== 'undefined') {
                currentMarker.infoWindowContent += '<br>' + data.zipCode + ' , ' + data.city + '</p>';
            } else {
                currentMarker.infoWindowContent += '</p>';
            }
        }

        if (data.url) {
            currentMarker.infoWindowContent += '<a href="' + data.url + '" class="gmap__overlay__link reverse-icon-pfeil_1">Zum Studio</a>';
        }

        if (typeof searchData !== 'undefined') {
            currentMarker.infoWindowContent += '<a class="gmap__overlay__link reverse-icon-pfeil_1" data-show-route="' + currentMarker.id + '">Route anzeigen</a>';
        }
        currentMarker.infoWindowContent += '<a href="https://www.google.com/maps/dir/?api=1&destination=' + data.city + ' + ' + data.street + '" target="_blank" class="gmap__overlay__link reverse-icon-pfeil_1">Anfahrt berechnen</a>';
        currentMarker.infoWindowContent += '</div></div>';

        const boxText = document.createElement('div');
        boxText.innerHTML = currentMarker.infoWindowContent;
        infoboxOptions.content = boxText;

        /*************************************************/

        import('google-maps-infobox').then((InfoBox) => {
            currentMarker.infobox = new InfoBox.InfoBox(infoboxOptions);

            // No EVENT LISTENER IF ONLY ONE MARKER (AND SET ACTIVE)
            if (this.markers.length <= 1) {
                currentMarker.infobox.open(this.map, currentMarker);
                this.activeInfoBoxMarker = currentMarker;
                this.map.panTo(new google.maps.LatLng(data.lat, data.lng)); //  eslint-disable-line
            }
        });

        // EVENT LISTENER FOR CLICK
        google.maps.event.addListener(currentMarker, "click", () => { //  eslint-disable-line
            if (this.activeInfoBoxMarker !== null) {
                this.activeInfoBoxMarker.infobox.close();
                this.activeInfoBoxMarker = null;
            }

            currentMarker.infobox.open(this.map, currentMarker);
            this.activeInfoBoxMarker = currentMarker;

            this.map.panTo(new google.maps.LatLng(data.lat, data.lng)); //  eslint-disable-line
            this.map.panBy(0, 100);
        });

        boxText.querySelector('.gmap__overlay__close').addEventListener('click', (e) => {
            this.activeInfoBoxMarker.infobox.close();
            e.preventDefault();
        });

        if (boxText.querySelector('[data-show-route]')) {
            boxText.querySelector('[data-show-route]').addEventListener('click', (e) => {
                e.preventDefault();

                this.activeInfoBoxMarker.infobox.close();
                this.showRoute(currentMarker);

                const thisForm = this.form[1];
                if (document.querySelector('div[data-ajaxform].form')) {
                    const $studioTeaserForm = document.querySelector('div[data-ajaxform].form').firstElementChild;
                    $studioTeaserForm.querySelector('input[name="zipCode"]').value = currentMarker.zipcode;
                    $studioTeaserForm.querySelector('input[name="latLng"]').value = currentMarker.lat + ',' + currentMarker.lng;
                    $studioTeaserForm.querySelector('input[name="location"]').value = thisForm.querySelector('input[name="studio_finder[location]"]').value;
                    $studioTeaserForm.dispatchEvent(this.studioFormSubmitEvent);
                }
            });
        }
    }

    changeMarker (OnOff, activeMarker, callback) {
        let markerIndex;

        if (OnOff === 'on') {
            // Change ClusterMarker
            if (this.mapProperties.clustering) {
                if (this.markerCluster.length > 0) {
                    this.markerCluster.styles_[0].url = this.icons[4].url;
                }
            }

            for (markerIndex = 0; markerIndex < this.markers.length; markerIndex++) {
                if (typeof activeMarker !== 'undefined') {
                    if (this.mapMarker[markerIndex].id === activeMarker.id) {
                        this.activeMarker.push(this.mapMarker[markerIndex]);
                        this.mapMarker[markerIndex].setMap(null);
                        this.mapMarker[markerIndex].icon = this.markerImages[0];
                        this.mapMarker[markerIndex].icon.scaledSize = new google.maps.Size(parseInt((this.icons[0].size[0] * 2)), parseInt((this.icons[0].size[1] * 2))); //  eslint-disable-line
                        this.mapMarker[markerIndex].icon.size = new google.maps.Size(parseInt((this.icons[0].size[0] * 2)), parseInt((this.icons[0].size[1] * 2))); //  eslint-disable-line
                        this.mapMarker[markerIndex].icon.anchor = new google.maps.Point(parseInt((this.icons[0].size[0])), parseInt((this.icons[0].size[1] * 2))); //  eslint-disable-line
                    } else {
                        this.mapMarker[markerIndex].setMap(null);
                        this.mapMarker[markerIndex].icon = this.markerImages[3];
                    }
                    this.mapMarker[markerIndex].setMap(this.map);
                }
            }
        } else {
            // Change ClusterMarker
            if (this.mapProperties.clustering) {
                if (this.markerCluster > 0) {
                    this.markerCluster.styles_[0].url = this.icons[1].url;
                }
            }

            for (markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                this.mapMarker[markerIndex].setMap(null);
                this.mapMarker[markerIndex].icon = this.markerImages[0];
                this.mapMarker[markerIndex].setMap(this.map);
            }
        }

        if (typeof callback === 'function') {
            callback();
        }
    }

    moveMap (x, y) {
        if (window.innerWidth >= 1024) {
            this.map.panBy(x, y);
        }
    }

    resetMap (callback) {
        this.changeMarker('off', '', () => {
            if (typeof this.mapMarker.yourlocation !== 'undefined') {
                if (this.mapMarker.yourlocation !== null) {
                    this.mapMarker.yourlocation.setMap(null);
                }
            }

            if (this.directionsDisplay !== null) {
                this.directionsDisplay.setMap(null);
            }

            // if (this.FTLayer !== null) {
            //     this.FTLayer.setMap(null);
            // }
            //
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    setCluster (imageURL) {
        this.markerCluster = new MarkerClusterer(this.map, this.mapMarker, {
            gridSize: 25,
            maxZoom: 19,
            styles: [{
                url: imageURL,
                width: 24,
                height: 36,
                textLineHeight: 26,
                textColor: '#ffffff',
                textSize: 12
            }]
        });
    }

    setBounds (marker, callback) {
        if (marker.length > 0) {
            if (marker.length > 1) {
                let bounds = new google.maps.LatLngBounds(); //  eslint-disable-line

                for (let bounceIndex = 0; bounceIndex < marker.length; bounceIndex++) {
                    if (marker[bounceIndex].lat !== 'undefined') {
                        bounds.extend(new google.maps.LatLng(marker[bounceIndex].lat, marker[bounceIndex].lng)); //  eslint-disable-line
                    }
                }

                this.map.setCenter(bounds.getCenter());
                this.map.fitBounds(bounds);

                if (typeof callback === 'function') {
                    callback();
                }
            } else {
                this.map.setCenter(new google.maps.LatLng(marker[0].lat, marker[0].lng)); //  eslint-disable-line
                this.map.setZoom(this.mapProperties.zoom);
            }
        }
    }

    processUserData (searchData) {
        const position = searchData.latlng.split(',');
        const boundsMarker = [];
        let shopPosition;

        for (let autoCompleteIndex = 0; autoCompleteIndex < this.mapProperties.autocompleteInput.length; autoCompleteIndex++) {
            this.mapProperties.autocompleteInput[autoCompleteIndex].value = searchData.location;
        }

        if (searchData.searchType === 'locality') {
            this.getLocation(JSON.parse(searchDataResult), searchData.zipcode , searchData.studioId); // eslint-disable-line
        } else {
            if (searchData.searchType === 'postal_code') {
                this.getLocation(JSON.parse(searchDataResult), searchData.zipcode, searchData.studioId);// eslint-disable-line
            } else {
                for (let markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                    if (this.mapMarker[markerIndex].id === parseInt(searchData.studioId)) {
                        boundsMarker.push(this.mapMarker[markerIndex]);
                        shopPosition = this.mapMarker[markerIndex].position;
                        this.changeMarker('on', this.mapMarker[markerIndex]);
                        break;
                    }
                }

                this.mapProperties.geolocation = { lat: position[0], lng: position[1] };
                this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
                    position: new google.maps.LatLng(position[0],position[1]), //  eslint-disable-line
                    map: this.map,
                    draggable: false,
                    lat: position[0],
                    lng: position[1],
                    id: 'yourlocation',
                    icon: this.markerImages[2],
                    zIndex: -20
                });

                for (let markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                    if (this.mapMarker[markerIndex].id === parseInt(searchData.studioId)) {
                        boundsMarker.push(this.mapMarker[markerIndex]);
                        shopPosition = this.mapMarker[markerIndex].position;
                        this.changeMarker('on', this.mapMarker[markerIndex]);
                        break;
                    }
                }

                // Push new Marker in BoundsMarker (for SetBounds)
                boundsMarker.push(this.mapMarker.yourlocation);

                this.setBounds(boundsMarker, () => {
                    if (this.useDirections) {
                        this.renderDirections(this.mapMarker.yourlocation.position, shopPosition, true, () => {
                            setTimeout(() => {
                                this.map.setZoom(this.map.getZoom() - 1);
                            }, 500);
                        });
                    }
                });
            }
        }
    }

    showRoute (targetMarker) {
        if (this.directionsDisplay !== null) {
            this.directionsDisplay.setMap(null);
        }

        this.renderDirections(this.mapMarker.yourlocation.position, targetMarker.position, true);
    }

    geoLocation (callback) {
        // const noGeolocation = (callback) => {
        //     if (this.mapProperties.setAlternativeGeolocation) {
        //         fetch('https://www.geoplugin.net/json.gp?jsoncallback=?').then(data => {
        //             this.mapProperties.geolocation = {
        //                 lat: parseFloat(data.geoplugin_latitude),
        //                 lng: parseFloat(data.geoplugin_longitude)
        //             };
        //
        //             if (typeof callback === 'function') {
        //                 callback(this.mapProperties.geolocation);
        //             } else {
        //                 this.nearestLocation(new google.maps.LatLng(this.mapProperties.geolocation.lat, this.mapProperties.geolocation.lng), 2, false); //  eslint-disable-line
        //             }
        //
        //             if (this.debug) {
        //                 console.log('GeoLocation Alternative ready. Set Alternative.', this.mapProperties.geolocation);
        //             }
        //         })
        //             .catch(err => {
        //                 if (this.debug) {
        //                     console.log(err);
        //                 }
        //             });
        //     } else {
        //         if (this.debug) {
        //             console.log('Do not Use GeoLocation Alternative (see your Settings).');
        //         }
        //     }
        // };
        //
        const showError = (error) => {
            if (error.code === error.PERMISSION_DENIED) {
                const $geoLocator = document.querySelectorAll('[data-geolocation]');
                for (let locatorIndex = 0; locatorIndex < $geoLocator.length; locatorIndex++) {
                    $geoLocator[locatorIndex].classList.add('has--tooltip');
                }

                if (this.debug) {
                    console.log('Geolocation Permission Denied', error);
                }

                if (typeof callback === 'function') {
                    callback(null);
                }
            }
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.mapProperties.geolocation = { lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) };

                if (typeof callback === 'function') {
                    const data = { lat: position.coords.latitude, lng: position.coords.longitude };
                    callback(data);
                }
            }, showError);
        } else {
            // noGeolocation((result) => {
            const $geoLocator = document.querySelectorAll('[data-geolocation]');
            for (let locatorIndex = 0; locatorIndex < $geoLocator.length; locatorIndex++) {
                $geoLocator[locatorIndex].classList.add('has--tooltip');
            }
            if (typeof callback === 'function') {
                callback(null);
            }
            // });
        }
    }

    renderDirections (origin, destination, preserveViewport, callback) {
        let routingLine = new google.maps.Polyline({ //  eslint-disable-line
            strokeColor: '#55c5d3'
        });
        const request = {
            origin: origin || 0,
            destination: destination || 0,
            travelMode: 'DRIVING'
        };

        if (preserveViewport !== true || preserveViewport !== false) {
            preserveViewport = true;
        }

        this.directionsService = new google.maps.DirectionsService(); //  eslint-disable-line
        this.directionsDisplay = new google.maps.DirectionsRenderer({ //  eslint-disable-line
            preserveViewport: preserveViewport || true,
            suppressMarkers: true
        });

        this.directionsDisplay.setOptions({ polylineOptions: routingLine });

        if (this.debug) {
            console.log('remove and Set Route:', this.directionsDisplay);
        }

        if (this.directionsDisplay !== null) {
            this.directionsDisplay.setMap(null);
        }

        this.directionsDisplay.setMap(this.map);
        this.directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                this.directionsDisplay.setDirections(result);
            } else {
                console.warn('Directions Route failed. Status: ' + status);
            }
        });

        if (typeof callback === 'function') {
            callback();
        }
    }

    autoComplete (formIndex) {
        const setAutoComplete = (index) => {
            const thisForm = this.form[index];

            this.mapProperties.autocomplete[index] = new google.maps.places.Autocomplete( //  eslint-disable-line
                /** @type {!HTMLInputElement} */ (
                    this.mapProperties.autocompleteInput[index]), {
                    //  types: ['(geocode)'],
                    componentRestrictions: this.mapProperties.countryRestrict
                });

            this.mapProperties.autocomplete[index].addListener('place_changed', () => {
                this.autoCompletePlace(thisForm, index);
            });

            thisForm.onsubmit = (e) => {
                if (thisForm.getAttribute('data-formaction') === 'ajax') {
                    e.preventDefault();
                }
                e.preventDefault();
            };

            // google.maps.event.addDomListener(this.mapProperties.autocompleteInput[index], 'keydown', (e) => { //  eslint-disable-line
            this.mapProperties.autocompleteInput[index].addEventListener('keydown', (e) => { //  eslint-disable-line
                if (!document.querySelector('.pac-item-selected')) {
                    this.dispatchForCode(e, (code) => {
                        if (code === 'Enter' && !e.triggered) {
                            this.selectFirstLocation(true, index);
                        }
                    });
                }
            });
        };

        setAutoComplete(formIndex);
    }

    selectFirstLocation (withEnter, index) {
        let evt;

        if (document.documentElement.classList.contains('ie11')) {
            evt = document.createEvent('KeyboardEvent');
            evt.initKeyboardEvent('keydown', true, false, window, 'Down', 0, 0, 0, 0);
            this.mapProperties.autocompleteInput[index].dispatchEvent(evt);

            if (withEnter) {
                evt.initKeyboardEvent('Enter', true, false, window, 'Enter', 0, 0, 0, 0);
                this.mapProperties.autocompleteInput[index].dispatchEvent(evt);
            }
        } else if (document.documentElement.classList.contains('no-csshover')) {
            const Event1 = new Event('keydown');
            Event1.code = 'ArrowDown';
            Event1.key = 'ArrowDown';
            Event1.keyCode = 40;
            google.maps.event.trigger(this.mapProperties.autocompleteInput[index], 'keydown', Event1); //  eslint-disable-line

            if (withEnter) {
                const Event2 = new Event('keydown');
                Event2.code = 'Enter';
                Event2.key = 'Enter';
                Event2.keyCode = 13;
                Event2.triggered = true;
                google.maps.event.trigger(this.mapProperties.autocompleteInput[index], 'keydown', Event2); //  eslint-disable-line
            }
        } else {
            evt = new KeyboardEvent('keydown', { keyCode: 40, which: 40, code: 'ArrowDown' });
            this.mapProperties.autocompleteInput[index].dispatchEvent(evt);
            if (withEnter) {
                this.mapProperties.autocompleteInput[index].dispatchEvent(new KeyboardEvent('keydown', { keyCode: 13, which: 13, code: 'Enter' }));
            }
        }
    }

    dispatchForCode (event, callback) {
        let code;

        if (event.key !== undefined) {
            code = event.key;
        } else if (event.keyIdentifier !== undefined) {
            code = event.keyIdentifier;
        } else if (event.keyCode !== undefined) {
            code = event.keyCode;
        }

        callback(code);
    }

    getStudioId (zipCode, thisForm, callback) {
        let radius = false;

        if (thisForm.querySelector('input[name="studio_finder[radius]"]')) {
            radius = thisForm.querySelector('input[name="studio_finder[radius]"]').value;
        }

        const formData = new FormData();

        formData.append('zipCode', zipCode);
        formData.append('latlng', thisForm.querySelector('input[name="studio_finder[latlng]"]').value);
        formData.append('radius', radius);
        formData.append('type', 'mapsearch');

        new AjaxLoad(thisForm.getAttribute('action'), { // eslint-disable-line
            requestMethod: 'post',
            requestData: formData,
            onSuccess: (data) => {
                data = JSON.parse(data);
                if (data.assignedStudioId) {
                    if (document.querySelector('div[data-ajaxform].form')) {
                        const $studioTeaserForm = document.querySelector('div[data-ajaxform].form').firstElementChild;

                        if ($studioTeaserForm) {
                            if ($studioTeaserForm.querySelector('input[name="zipCode"]')) {
                                $studioTeaserForm.querySelector('input[name="zipCode"]').value = data.zipCode;
                                $studioTeaserForm.querySelector('input[name="latLng"]').value = thisForm.querySelector('input[name="studio_finder[latlng]"]').value;
                                $studioTeaserForm.querySelector('input[name="location"]').value = thisForm.querySelector('input[name="studio_finder[location]"]').value;
                                $studioTeaserForm.dispatchEvent(this.studioFormSubmitEvent);
                            }
                        }

                        callback(data.assignedStudioId, data.zipCode);
                    } else {
                        callback(data.assignedStudioId, data.zipCode);
                    }
                } else {
                    if (data.template) {
                        callback(data.studioIds, data.zipCode, data.template, parseInt(data.template_count));
                    }
                }
            },
            onError: (status) => {
                console.warn('Studio Request failed due ' + status);
            }
        });
    }

    dataToTemplate (data, counter, template) {
        const $target = document.querySelector(template);

        if ($target) {
            const $counter = document.querySelector('.form__result--count');

            if (counter > 1 || counter === 0) {
                $counter.innerHTML = counter + ' Ergebnisse';
            } else {
                $counter.innerHTML = counter + ' Ergebniss';
            }
            $target.innerHTML = data;
            this.initResults($target);
        }
    }

    initResults ($content) {
        let index = 0;
        const loaders = [];

        import('../../services/loader').then(({ Loader }) => {
            const $loaders = $content.querySelectorAll('[data-loader]');

            if ($loaders.length > 0) {
                $loaders.forEach(($loader) => {
                    loaders[index] = new Loader($loader, {
                        loading: '<b class="loading loading--show"><b class="loader"><i>loading...</i></b></b>',
                        onSubmitted: ($results) => {
                            this.initResults($results);
                        }
                    });
                    index++;
                });
            }
        });
    }

    removeVisualRadius () {
        if (this.circle !== null) {
            this.circle.setMap(null); // eslint-disable-line
            this.circle = null;
        }
    }

    setVisualRadius (lat, lng, radius) {
        this.removeVisualRadius();

        this.circle = new google.maps.Circle({ // eslint-disable-line
            strokeColor: '#55c5d3',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#55c5d3',
            fillOpacity: 0.05,
            map: this.map,
            center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            },
            radius: radius * 1000
        });
    }

    getZoomByMeters (meters, lat) {
        let mapWidth = 0;
        const pixelRatio = window.devicePixelRatio;
        const $mapWrapper = this.mapContainer.closest('[data-js-mapwrap]');

        if ($mapWrapper.clientHeight <= $mapWrapper.clientWidth) {
            mapWidth = ($mapWrapper.clientHeight / pixelRatio);
        } else {
            mapWidth = ($mapWrapper.clientWidth / pixelRatio);
        }

        const latitudinalAdjustment = Math.cos(Math.PI * lat / 180.0);
        const arg = 40075004 * mapWidth * latitudinalAdjustment / (meters * 256.0);

        return (Math.log(arg) / Math.log(2.0));
    }

    autoCompletePlace (thisForm, index) {
        const result = this.mapProperties.autocomplete[index].getPlace();

        if (typeof result.geometry === 'undefined') {
            let autocompleteService = new google.maps.places.AutocompleteService(); //  eslint-disable-line
            autocompleteService.getPlacePredictions({
                input: result.name,
                offset: result.name.length
            });
        } else {
            if (thisForm.getAttribute('data-formaction') === 'post') {
                thisForm.querySelector('input[name="studio_finder[latlng]"]').value = result.geometry.location.lat() + ',' + result.geometry.location.lng();

                if (result.types[0] === 'postal_code') {
                    thisForm.querySelector('input[name="studio_finder[zipCode]"]').value = result.name;
                } else {
                    thisForm.querySelector('input[name="studio_finder[zipCode]"]').value = this.getZipCode('latlng', result);
                }

                thisForm.querySelector('input[name="studio_finder[searchType]"]').value = result.types[0];
                thisForm.querySelector('input[name="studio_finder[result]"]').value = JSON.stringify({
                    geometry: result.geometry,
                    name: result.name,
                    types: result.types
                });
                thisForm.submit();
            } else {
                //  AJAX FORM SUBMIT
                thisForm.querySelector('input[name="studio_finder[latlng]"]').value = result.geometry.location.lat() + ',' + result.geometry.location.lng();
                if (result.types[0] === 'route' || result.types[0] === 'neighborhood' || result.types[0] === 'street_address' || result.types[0] === 'locality') {
                    this.getZipCode('object', result, (zipCode) => {
                        this.getStudioId(zipCode, thisForm, (studioId, receivedZipCode, templateData, count) => {
                            if (zipCode === false) {
                                if (receivedZipCode) {
                                    zipCode = receivedZipCode;
                                }
                            }

                            if (templateData) {
                                this.resetMap();
                                this.dataToTemplate(templateData, count, '[data-mapsearch-results]');
                                this.activateAndFitMarker(studioId, (activeMarkers) => {
                                    if (!this.radius) {
                                        this.setBounds(activeMarkers, () => {
                                            this.map.setZoom(this.map.getZoom() - 1);
                                        });
                                    }
                                });
                            } else {
                                this.getLocation(result, zipCode, studioId);
                            }
                        });
                    });
                } else {
                    if (result.types[0] === 'postal_code') {
                        this.getZipCode('postal_code', result, (zipCode) => {
                            this.getStudioId(zipCode, thisForm, (studioId, receivedZipCode, templateData, count) => {
                                if (zipCode === false) {
                                    if (receivedZipCode) {
                                        zipCode = receivedZipCode;
                                    }
                                }

                                if (templateData) {
                                    this.dataToTemplate(templateData, count, '[data-mapsearch-results]');
                                    this.activateAndFitMarker(studioId, (activeMarkers) => {
                                        if (!this.radius) {
                                            this.setBounds(activeMarkers, () => {
                                                this.map.setZoom(this.map.getZoom() - 1);
                                            });
                                        }
                                    });
                                } else {
                                    this.getLocation(result, zipCode, studioId);
                                }
                            });
                        });
                    } else {
                        this.getZipCode('object', result, (zipCode) => {
                            this.getStudioId(zipCode, thisForm, (studioId, receivedZipCode, templateData, count) => {
                                if (zipCode === false) {
                                    if (receivedZipCode) {
                                        zipCode = receivedZipCode;
                                    }
                                }

                                if (templateData) {
                                    this.resetMap();
                                    this.dataToTemplate(templateData, count, '[data-mapsearch-results]');
                                    this.activateAndFitMarker(studioId, (activeMarkers) => {
                                        if (!this.radius) {
                                            this.setBounds(activeMarkers, () => {
                                                this.map.setZoom(this.map.getZoom() - 1);
                                            });
                                        }
                                    });
                                } else {
                                    this.getLocation(result, zipCode, studioId);
                                }
                            });
                        });
                    }
                }

                if (this.radius) {
                    this.map.setCenter(result.geometry.location);
                    const lat = this.map.getCenter().lat();
                    const radius = this.radius.querySelector('input[name="studio_finder[radius]"]').value;
                    this.setVisualRadius(lat, this.map.getCenter().lng(), parseInt(radius));
                    const zoom = this.getZoomByMeters((radius * 1000), lat);
                    this.map.setZoom(Math.floor(zoom) - 1);
                }
            }
        }
    }

    activateAndFitMarker (studioIds, callback) {
        this.activeMarker = [];

        for (let markerIndex = 0; markerIndex < this.markers.length; markerIndex++) {
            if (studioIds.indexOf(this.markers[markerIndex].id.toString()) !== -1) {
                this.activeMarker.push(this.markers[markerIndex]);
                this.mapMarker[markerIndex].icon = this.markerImages[0];
                this.mapMarker[markerIndex].icon.scaledSize = new google.maps.Size(parseInt((this.icons[0].size[0] * 2)), parseInt((this.icons[0].size[1] * 2))); //  eslint-disable-line
                this.mapMarker[markerIndex].icon.size = new google.maps.Size(parseInt((this.icons[0].size[0] * 2)), parseInt((this.icons[0].size[1] * 2))); //  eslint-disable-line
                this.mapMarker[markerIndex].icon.anchor = new google.maps.Point(parseInt((this.icons[0].size[0])), parseInt((this.icons[0].size[1] * 2))); //  eslint-disable-line
            } else {
                this.mapMarker[markerIndex].icon = this.markerImages[3];
            }
            this.mapMarker[markerIndex].setMap(this.map);
        }

        if (typeof callback === 'function') {
            callback(this.activeMarker);
        }
    }

    getZipCode (type, results, callback) {
        let zipCode = false;
        let newResult = '';

        if (type === 'latlng') {
            new AjaxLoad('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + results.geometry.location.lat() + ',' + results.geometry.location.lng() + '&sensor=false&key=' + googleMapsGeocodeApiKey + '', { // eslint-disable-line
                requestMethod: 'get',
                addXRequestedWith: false, // Do not add X-Requested-With header for this request
                onSuccess: (data) => {
                    const parsedData = tryParseJSON(data);

                    if (this.debug) {
                        console.log(data.status, data, results);
                    }

                    if (parsedData.results.length > 0) {
                        for (let dataIndex = 0; dataIndex < parsedData.results.length; dataIndex++) {
                            const addresses = parsedData.results[dataIndex].address_components;

                            if (addresses.length > 0) {
                                for (let addressIndex = 0; addressIndex < addresses.length; addressIndex++) {
                                    if (addresses[addressIndex].types.includes('postal_code')) {
                                        zipCode = addresses[addressIndex].long_name;
                                        break;
                                    }
                                }
                            }

                            if (zipCode !== false) {
                                break;
                            }
                        }
                    }
                },
                onError: (status) => {
                    console.warn('Studio Request failed due ' + status);
                }
            });
        }

        if (type === 'postal_code') {
            if (results) {
                zipCode = results.name;
            }
        }

        if (type === 'object') {
            if (results) {
                let addresses;

                if (typeof results.length !== 'undefined') {
                    for (let resultsIndex = 0; resultsIndex < results.length; resultsIndex++) {
                        addresses = results[resultsIndex].address_components;

                        if (addresses.length > 0) {
                            for (let addressesIndex = 0; addressesIndex < addresses.length; addressesIndex++) {
                                if (addresses[addressesIndex].types === 'postal_code') {
                                    zipCode = addresses[addressesIndex].long_name;
                                    newResult = results[resultsIndex];
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    addresses = results.address_components;

                    if (addresses.length > 0) {
                        for (let addressIndex = 0; addressIndex < addresses.length; addressIndex++) {
                            if (addresses[addressIndex].types === 'postal_code') {
                                zipCode = addresses[addressIndex].long_name;
                                break;
                            }
                        }
                    }
                }
            }
        }

        if (typeof callback === 'function') {
            if (newResult !== '') {
                callback(zipCode, newResult);
            } else {
                callback(zipCode);
            }
        } else {
            return zipCode;
        }
    }

    codeAddress (value, callback) {
        this.geocoder.geocode({ address: value }, (results, status) => {
            if (status === 'OK') {
                callback(results);
            } else {
                alert('Geocode for Adress was not successful for the following reason: ' + status);
            }
        });
    }

    codeLatLng (value, callback) {
        this.geocoder.geocode({ location: value }, (results, status) => {
            if (status === 'OK') {
                callback(results);
            } else {
                alert('Geocode for Location was not successful for the following reason: ' + status);
            }
        });
    }

    getLocation (result, zipCode, studioId) {
        const type = result.types[0];
        // const boundsCount = 0;
        let markerIndex;
        const boundsMarker = [];
        let shopPosition;
        let yourLat;
        let yourLng;

        this.resetMap();
        this.mapProperties.autocompleteBoundsMarker = new google.maps.LatLngBounds(); //  eslint-disable-line
        //  this.nearestLocation(result.geometry.location);

        if (this.debug) {
            console.log('SearchType: ' + type, 'ZipCode: ' + zipCode);
        }

        const checkYourLatLng = (location) => {
            if (typeof location.lat !== 'function') {
                yourLat = location.lat;
                yourLng = location.lng;
            } else {
                yourLat = location.lat();
                yourLng = location.lng();
            }
        };

        switch (type) {
        case ('postal_code'): {
            // if (typeof zipCode !== 'undefined' && zipCode !== false) {
            //     setFTLayer(type, zipCode);
            // }
            this.map.setCenter(result.geometry.location);
            this.map.fitBounds(result.geometry.viewport);

            if (this.radius) {
                const radius = this.radius.querySelector('input[name="studio_finder[radius]"]').value;
                const lat = this.map.getCenter().lat();

                this.setVisualRadius(lat, this.map.getCenter().lng(), parseInt(radius));
                const zoom = this.getZoomByMeters((radius * 1000), lat);
                this.map.setZoom(Math.floor(zoom) - 1);
            }

            if (typeof studioId !== 'undefined') {
                for (markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                    if (this.mapMarker[markerIndex].id === parseInt(studioId)) {
                        boundsMarker.push(this.mapMarker[markerIndex]);
                        shopPosition = this.mapMarker[markerIndex].position;
                        this.changeMarker('on', this.mapMarker[markerIndex]);
                        break;
                    }
                }

                if (typeof result.geometry.viewport.south !== 'undefined') {
                    boundsMarker.push({ lat: result.geometry.viewport.south, lng: result.geometry.viewport.west, id: 'SouthWest' });
                    boundsMarker.push({ lat: result.geometry.viewport.north, lng: result.geometry.viewport.east, id: 'NorthEast' });
                } else {
                    boundsMarker.push({ lat: result.geometry.viewport.getNorthEast().lat(), lng: result.geometry.viewport.getNorthEast().lng(), id: 'northEast' });
                    boundsMarker.push({ lat: result.geometry.viewport.getSouthWest().lat(), lng: result.geometry.viewport.getSouthWest().lng(), id: 'southWest' });
                }

                checkYourLatLng(result.geometry.location);

                // Add new YOURLOCATION-Marker on Map
                this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
                    position: result.geometry.location,
                    map: this.map,
                    draggable: false,
                    lat: yourLat,
                    lng: yourLng,
                    id: 'yourlocation',
                    icon: this.markerImages[2],
                    zIndex: -20
                });

                // Push new Marker in BoundsMarker (for SetBounds)
                boundsMarker.push(this.mapMarker.yourlocation);

                this.setBounds(boundsMarker, () => {
                    if (this.useDirections) {
                        this.renderDirections(this.mapMarker.yourlocation.position, shopPosition, true, () => {
                            setTimeout(() => {
                                this.map.setZoom(this.map.getZoom() - 1);
                            }, 500);
                        });
                    }
                });
            }
            break;
        }
        case ('locality'): {
            this.map.setCenter(result.geometry.location);
            this.map.fitBounds(result.geometry.viewport);

            if (this.radius) {
                const radius = this.radius.querySelector('input[name="studio_finder[radius]"]').value;
                const lat = this.map.getCenter().lat();

                this.setVisualRadius(lat, this.map.getCenter().lng(), parseInt(radius));
                const zoom = this.getZoomByMeters((radius * 1000), lat);
                this.map.setZoom(Math.floor(zoom) - 1);
            }

            if (typeof result.geometry.location.lat === 'number') {
                result.geometry.location = new google.maps.LatLng(result.geometry.location.lat, result.geometry.location.lng); //  eslint-disable-line
            }

            if (typeof studioId !== 'undefined') {
                for (markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                    if (this.mapMarker[markerIndex].id === parseInt(studioId)) {
                        boundsMarker.push(this.mapMarker[markerIndex]);
                        shopPosition = this.mapMarker[markerIndex].position;
                        this.changeMarker('on', this.mapMarker[markerIndex]);
                        break;
                    }
                }

                if (typeof result.geometry.viewport.south !== 'undefined') {
                    boundsMarker.push({ lat: result.geometry.viewport.south, lng: result.geometry.viewport.west, id: 'SouthWest' });
                    boundsMarker.push({ lat: result.geometry.viewport.north, lng: result.geometry.viewport.east, id: 'NorthEast' });
                } else {
                    boundsMarker.push({ lat: result.geometry.viewport.getNorthEast().lat(), lng: result.geometry.viewport.getNorthEast().lng(), id: 'northEast' });
                    boundsMarker.push({ lat: result.geometry.viewport.getSouthWest().lat(), lng: result.geometry.viewport.getSouthWest().lng(), id: 'southWest' });
                }

                checkYourLatLng(result.geometry.location);

                // Add new YOURLOCATION-Marker on Map
                this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
                    position: result.geometry.location,
                    map: this.map,
                    lat: yourLat,
                    lng: yourLng,
                    draggable: false,
                    id: 'yourlocation',
                    icon: this.markerImages[2],
                    zIndex: -20
                });

                // Push new Marker in BoundsMarker (for SetBounds)
                boundsMarker.push(this.mapMarker.yourlocation);

                this.setBounds(boundsMarker, () => {
                    if (this.useDirections) {
                        this.renderDirections(this.mapMarker.yourlocation.position, shopPosition, true, () => {
                            setTimeout(() => {
                                this.map.setZoom(this.map.getZoom() - 1);
                            }, 500);
                        });
                    }
                });
            }
            break;
        }
        case ('neighborhood'):
        case ('route'): {
            if (typeof studioId !== 'undefined') {
                for (markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                    if (this.mapMarker[markerIndex].id === parseInt(studioId)) {
                        boundsMarker.push(this.mapMarker[markerIndex]);
                        shopPosition = this.mapMarker[markerIndex].position;
                        this.changeMarker('on', this.mapMarker[markerIndex]);
                        break;
                    }
                }

                checkYourLatLng(result.geometry.location);

                // Add new YOURLOCATION-Marker on Map
                this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
                    position: result.geometry.location,
                    map: this.map,
                    lat: yourLat,
                    lng: yourLng,
                    draggable: false,
                    id: 'yourlocation',
                    icon: this.markerImages[2],
                    zIndex: -20
                });

                // Push new Marker in BoundsMarker (for SetBounds)
                boundsMarker.push(this.mapMarker.yourlocation);

                this.setBounds(boundsMarker, () => {
                    if (this.useDirections) {
                        this.renderDirections(this.mapMarker.yourlocation.position, shopPosition, false, () => {
                            setTimeout(() => {
                                this.map.setZoom(this.map.getZoom() - 1);
                            }, 500);
                        });
                    }
                });
            }
            break;
        }
        case ('street_address'): {
            if (typeof studioId !== 'undefined') {
                for (markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                    if (this.mapMarker[markerIndex].id === parseInt(studioId)) {
                        boundsMarker.push(this.mapMarker[markerIndex]);
                        shopPosition = this.mapMarker[markerIndex].position;
                        this.changeMarker('on', this.mapMarker[markerIndex]);
                        break;
                    }
                }

                checkYourLatLng(result.geometry.location);

                // Add new YOURLOCATION-Marker on Map
                this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
                    position: result.geometry.location,
                    map: this.map,
                    lat: yourLat,
                    lng: yourLng,
                    draggable: false,
                    id: 'yourlocation',
                    icon: this.markerImages[2],
                    zIndex: -20
                });

                // Push new Marker in BoundsMarker (for SetBounds)
                boundsMarker.push(this.mapMarker.yourlocation);

                this.setBounds(boundsMarker);

                if (this.useDirections) {
                    this.renderDirections(this.mapMarker.yourlocation.position, shopPosition, false, () => {
                        setTimeout(() => {
                            this.map.setZoom(this.map.getZoom() - 1);
                        }, 500);
                    });
                }
            }
            break;
        }
        default: {
            if (typeof studioId !== 'undefined') {
                for (markerIndex = 0; markerIndex < this.mapMarker.length; markerIndex++) {
                    if (this.mapMarker[markerIndex].id === parseInt(studioId)) {
                        boundsMarker.push(this.mapMarker[markerIndex]);
                        shopPosition = this.mapMarker[markerIndex].position;

                        this.changeMarker('on', this.mapMarker[markerIndex]);
                        break;
                    }
                }

                checkYourLatLng(result.geometry.location);

                // Add new YOURLOCATION-Marker on Map
                this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
                    position: result.geometry.location,
                    map: this.map,
                    lat: yourLat,
                    lng: yourLng,
                    draggable: false,
                    id: 'yourlocation',
                    icon: this.markerImages[2],
                    zIndex: -20
                });

                // Push new Marker in BoundsMarker (for SetBounds)
                boundsMarker.push(this.mapMarker.yourlocation);

                this.setBounds(boundsMarker, () => {
                    if (this.useDirections) {
                        this.renderDirections(this.mapMarker.yourlocation.position, shopPosition, false, () => {
                            setTimeout(() => {
                                this.map.setZoom(this.map.getZoom() - 1);
                            }, 500);
                        });
                    }
                });
            }
            break;
        }
        }

        smoothScrollTo(0, 0, 500);
    }

    nearestLocation (position, findCount, printRoute) {
        let nearestMarker = [];
        const boundsMarker = [];
        let distance = [];

        if (findCount <= 0) {
            findCount = 1;
        }

        for (let markerIndex = 0; markerIndex < this.markers.length; markerIndex++) {
            if (this.markers[markerIndex].lat !== 0 && this.markers[markerIndex].lng !== 0) {
                distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(this.markers[markerIndex].lat, this.markers[markerIndex].lng), position); //  eslint-disable-line

                nearestMarker[markerIndex] = this.markers[markerIndex];
                nearestMarker[markerIndex].distance = distance;
            }
        }

        const compare = (a, b) => {
            const distanceA = a.distance;
            const distanceB = b.distance;

            let comparison = 0;
            if (distanceA > distanceB) {
                comparison = 1;
            } else if (distanceA < distanceB) {
                comparison = -1;
            }
            return comparison;
        };

        nearestMarker = nearestMarker.sort(compare);
        nearestMarker = nearestMarker.slice(0, findCount);

        // push nearestMarker into boundsMarkers (for setBounds)
        for (let pushIndex = 0; pushIndex < nearestMarker.length; pushIndex++) {
            boundsMarker.push(nearestMarker[pushIndex]);
        }

        // Add new YOURLOCATION-Marker on Map
        this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
            position: position || 0,
            map: this.map,
            draggable: false,
            id: 'yourlocation',
            icon: this.markerImages[2],
            zIndex: -20
        });

        // Push new Marker in BoundsMarker (for SetBounds)
        boundsMarker.push({ lat: position.lat(), lng: position.lng(), ItemId: 'yourlocation' });

        this.setBounds(boundsMarker);

        if (findCount <= 1) {
            this.changeMarker('on', nearestMarker[0]);
        }

        if (printRoute) {
            this.renderDirections(position, new google.maps.LatLng(nearestMarker[0].lat, nearestMarker[0].lng), false); //  eslint-disable-line
        }
    }

    //  REACTON EVENT WHO WAS FIRED
    events () {
        const $geoLocator = document.querySelectorAll('[data-geolocation]');
        const $fullScreenButton = document.querySelector('[data-map-fullscreen]');
        const $smallScreenButton = document.querySelector('[data-map-smallscreen]');

        const LocateMeEvent = (locateButton) => {
            locateButton.addEventListener('click', (e) => {
                const $parentElement = locateButton.parentElement;

                $parentElement?.appendChild(createElementFromHTML(window.OPTIONS.loading));
                $parentElement?.classList.add('is--disabled');

                if (e.currentTarget.getAttribute('data-pos-value') === null) {
                    this.geoLocation((latlng) => {
                        $parentElement?.querySelector('.loading')?.remove();
                        $parentElement?.classList.remove('is--disabled');

                        if (latlng !== null) {
                            const nearestForm = locateButton.closest('form');
                            nearestForm.querySelector('input[name="studio_finder[latlng]"]').value = latlng.lat + ',' + latlng.lng;

                            if (this.radius) {
                                const radius = this.radius.querySelector('input[name="studio_finder[radius]"]').value;
                                const lat = latlng.lat;

                                this.setVisualRadius(lat, latlng.lng, parseInt(radius));
                                const zoom = this.getZoomByMeters((radius * 1000), lat);
                                this.map.setZoom(Math.floor(zoom) - 1);
                            }

                            if (nearestForm.getAttribute('data-formaction') === 'post') {
                                this.getStudioId('', nearestForm, (studioId, zipCode) => {
                                    this.codeAddress(zipCode, (result) => {
                                        result[0].geometry.location = latlng;
                                        nearestForm.querySelector('input[name="studio_finder[location]"]').value = zipCode + ', ' + this.mapDefaultProperties.country;
                                        nearestForm.querySelector('input[name="studio_finder[zipCode]"]').value = zipCode;
                                        nearestForm.querySelector('input[name="studio_finder[searchType]"]').value = result[0].types[0];
                                        nearestForm.querySelector('input[name="studio_finder[result]"]').value = JSON.stringify({
                                            geometry: result[0].geometry,
                                            name: result[0].name,
                                            types: result[0].types
                                        });

                                        nearestForm.submit();
                                    });
                                });
                            } else {
                                this.codeLatLng(latlng, (result) => {
                                    nearestForm.querySelector('input[name="studio_finder[location]"]').value = result[0].formatted_address;

                                    this.getStudioId('', locateButton.closest('form'), (studioId, zipCode, templateData, count) => {
                                        if (nearestForm.querySelector('input[name="studio_finder[radius]"]')) {
                                            if (templateData) {
                                                this.dataToTemplate(templateData, count, '[data-mapsearch-results]');
                                            }
                                        }

                                        this.codeAddress(zipCode, (result) => {
                                            result[0].geometry.location = latlng;
                                            this.getLocation(result[0], zipCode, studioId);
                                        });
                                    });
                                });
                            }
                        }
                    });
                }
            });
        };

        const setNewRadius = (actualPos) => {
            this.radiusValue.setAttribute('data-pos', actualPos);
            this.radiusValue.textContent = this.radiusSteps[actualPos].radius;
            this.radius.querySelector('input[name="studio_finder[radius]"]').value = this.radiusSteps[actualPos].radius;
            const autoCompleteInput = this.radius.closest('form')?.querySelector('.pac-target-input');

            if (autoCompleteInput) {
                if (autoCompleteInput.value !== '') {
                    this.mapProperties.autocompleteInput.forEach((input, index) => {
                        if (input === autoCompleteInput) {
                            this.autoCompletePlace(this.radius.closest('form'), index);
                        }
                    });
                }
            }
        };

        const setSubmitEvent = (formIndex) => {
            this.formSubmit[formIndex].addEventListener('click', () => {
                this.selectFirstLocation(true, formIndex);
            });
        };

        for (let geoIndex = 0; geoIndex < $geoLocator.length; geoIndex++) {
            LocateMeEvent($geoLocator[geoIndex]);
        }

        if (this.radiusValue) {
            let actualPos = this.radiusValue.getAttribute('data-pos');

            this.radius.querySelector('.radius__minus').addEventListener('click', () => {
                if (actualPos - 1 >= 0) {
                    actualPos--;
                    setNewRadius(actualPos);
                }
            });

            this.radius.querySelector('.radius__plus').addEventListener('click', () => {
                if (actualPos + 1 < this.radiusSteps.length) {
                    actualPos++;
                    setNewRadius(actualPos);
                }
            });
        }

        if ($fullScreenButton) {
            $fullScreenButton.addEventListener('click', () => {
                const $mapWrapper = $fullScreenButton.closest('[data-js-mapwrap]');
                const $studioFinder = document.querySelector('[data-studio-finder]');

                $mapWrapper.classList.remove('has--notrans');
                $mapWrapper.classList.add('is--fullscreen');

                setTimeout(() => {
                    smoothScrollTo(0, $studioFinder[0].offsetTop, 500);
                }, 50);
            });
        }

        if ($smallScreenButton) {
            $smallScreenButton.addEventListener('click', () => {
                const $mapWrapper = $smallScreenButton.closest('[data-js-mapwrap]');

                $mapWrapper.classList.remove('is--fullscreen');
            });
        }

        for (let formIndex = 0; formIndex < this.form.length; formIndex++) {
            setSubmitEvent(formIndex);
        }

        if (this.mapContainer) {
            google.maps.event.addListenerOnce(this.map, 'idle', () => { //  eslint-disable-line
                this.mapReady = true;
            });

            this.map.addListener('click', () => {
                if (this.activeInfoBoxMarker !== null && this.markers.length > 1) {
                    this.activeInfoBoxMarker.infobox.close();
                    this.activeInfoBoxMarker = null;
                }
            });
        }

        document.addEventListener('design_m', () => {
            const $mapWrapper = document.querySelector('[data-js-mapwrap]');

            if ($mapWrapper) {
                $mapWrapper.classList.remove('is--fullscreen');
                document.querySelector('body').classList.remove('no--scroll');
            }
        });

        /***************************************************/
        //  PRE-DEFINES LOCATIONS FOR MY LOCATION
        const preDefinedLocationsEvent = (index) => {
            this.preDefinedLocations[index].addEventListener('click', (e) => {
                if (typeof this.mapMarker.yourlocation !== 'undefined') {
                    if (this.mapMarker.yourlocation !== null) {
                        this.mapMarker.yourlocation.setMap(null);
                    }
                }

                if (this.directionsDisplay !== null) {
                    this.directionsDisplay.setMap(null);
                }

                const drawRoute = (position) => {
                    const boundsMarker = [];

                    boundsMarker.push(this.mapMarker[0]);
                    this.mapMarker.yourlocation = new google.maps.Marker({ //  eslint-disable-line
                        position: position || 0,
                        map: this.map,
                        draggable: false,
                        id: 'yourlocation',
                        icon: this.markerImages[2],
                        zIndex: -20
                    });

                    boundsMarker.push({ lat: position.lat(), lng: position.lng(), ItemId: 'yourlocation' });

                    this.setBounds(boundsMarker);
                    this.renderDirections(position, new google.maps.LatLng(this.mapMarker[0].lat, this.mapMarker[0].lng), false); //  eslint-disable-line
                };

                if (e.currentTarget.getAttribute('data-pos-value') === '') {
                    this.geoLocation((result) => {
                        drawRoute(new google.maps.LatLng(result.lat, result.lng)); //  eslint-disable-line
                    });
                } else {
                    this.codeAddress(e.currentTarget.getAttribute('data-pos-value'), (result) => {
                        drawRoute(result[0].geometry.location);
                    });
                }
            });
        };

        for (let preIndex = 0; preIndex < this.preDefinedLocations.length; preIndex++) {
            preDefinedLocationsEvent(preIndex);
        }
        /***************************************************/
    }
}

export { StudioFinder };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    window.studioFinderLoaded = true;

    const $studioFinder = $context.querySelectorAll('[data-studio-finder]');
    setTimeout(() => {
        $studioFinder.forEach((studioFinder) => {
            window.inViewObserver.observe(studioFinder);
        });
    }, 200);
});

window.addEventListener('googleApiLoaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $autoComplete = $context.querySelectorAll('[data-autocomplete]');
        const $gmapContainer = $context.querySelector('[data-js-gmap]');

        if ($gmapContainer) {
            new StudioFinder($gmapContainer, {  // eslint-disable-line
                lat: $gmapContainer.getAttribute('data-lat'),
                lng: $gmapContainer.getAttribute('data-lng'),
                country: $gmapContainer.getAttribute('data-country'),
                zoom: 9,
                zoomControl: true,
                container: document.querySelector('[data-studio-finder]')
            });
        } else if ($autoComplete.length > 0) {
            new StudioFinder(); // eslint-disable-line
        }
    }
});

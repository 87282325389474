const createElementFromHTML = (htmlString) => {
    if (htmlString !== '' && htmlString !== null) {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        if (div.children.length > 1) {
            const itemsArray = [];
            Array.prototype.push.apply(itemsArray, div.children);

            return itemsArray;
        } else {
            return div.firstChild;
        }
    } else {
        return null;
    }
};

const getEntries = (obj) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
};

const getParent = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function (s) {
            const matches = (this.document || this.ownerDocument).querySelectorAll(s);
            let i = matches.length;
            // eslint-disable-next-line no-empty
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
};

const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        bottom: rect.top + scrollTop + rect.height
    };
};

const loadImage = (img, loadHandler, errorHandler) => {
    const helperImg = new Image();
    if (loadHandler) {
        helperImg.addEventListener('load', () => {
            loadHandler(img);
        }, false);
    }
    if (errorHandler) {
        helperImg.addEventListener('error', () => {
            errorHandler(img, false);
        }, false);

        helperImg.addEventListener('abort', () => {
            errorHandler(img, true);
        }, false);
    }
    helperImg.src = img.src;
};

const loadScript = (source, beforeEl, async = true, defer = true) => {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        const prior = beforeEl || document.getElementsByTagName('script')[0];

        script.async = async;
        script.defer = defer;

        function onloadHander (_, isAbort) {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = null;
                script.onreadystatechange = null;
                script = undefined;

                if (isAbort) {
                    const error = new Error('something went wrong');
                    reject(error);
                } else {
                    resolve(script);
                }
            }
        }

        script.onload = onloadHander;
        script.onreadystatechange = onloadHander;

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
    });
};

const loadYoutubeApi = () => {
    if (typeof YT === 'undefined') {
        window.youtubeApiLoading = true;
        const tag = document.createElement('script');
        tag.src = '//www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};

// const loadReCaptcha = (element) => {
//     console.log(element);
//     const tag = document.createElement('script');
//     tag.src = element.dataset.src;
//
//     const firstScriptTag = document.getElementsByTagName('script')[0];
//     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
//     tag.async = true;
//     tag.defer = true;
//
//     console.log('set script');
//     window.onRecaptchaLoaded = () => {
//         console.log('onRecaptchaLoaded');
//         const $container = element.nextElementSibling;
//
//         grecaptcha.render($container.getAttribute('id'), { // eslint-disable-line
//             sitekey: $container.dataset.sitekey
//         });
//     };
// };

const loadGoogleApi = () => {
    import('@googlemaps/js-api-loader').then(({ Loader }) => {
        window.googleApiLoader = new Loader({
            apiKey: googleMapsApiKey, // eslint-disable-line
            version: 'quarterly',
            libraries: ['places']
        });

        window.googleApiLoader.load().then(() => {
            window.googleApiReady = true;

            const googleApiLoadedEvent = new CustomEvent('googleApiLoaded', {
                view: window,
                bubbles: true,
                cancelable: false,
                detail: {
                    $context: document
                }
            });

            window.dispatchEvent(googleApiLoadedEvent);
        });
    });
};

const smoothScrollTo = (endX, endY, duration) => {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);

        if (time >= duration) {
            window.clearInterval(timer);
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

const containerSmoothScrollTo = ($container, endX, endY, duration) => {
    const startX = $container.scrollLeft;
    const startY = $container.scrollTop;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);

        if (time >= duration) {
            window.clearInterval(timer);
        }
        $container.scrollLeft = newX;
        $container.scrollTop = newY;
    }, 1000 / 60); // 60 fps
};

const tryParseJSON = (jsonString) => {
    try {
        const o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) {
        console.log('Variable: ' + jsonString + ' \nFehler: ' + e);
    }

    return false;
};

const uniqueID = () => {
    const chr4 = () => {
        return Math.random().toString(16).slice(-4);
    };

    return chr4() + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
};

// ** FADE OUT FUNCTION **
const fadeOut = (el) => {
    el.style.opacity = 1;
    (function fade () {
        if ((el.style.opacity -= 0.1) < 0) {
            el.style.display = 'none';
        } else {
            requestAnimationFrame(fade);
        }
    })();
};

// ** FADE IN FUNCTION **
const fadeIn = (el, display) => {
    el.style.opacity = 0;
    el.style.display = display || 'block';
    (function fade () {
        let val = parseFloat(el.style.opacity);
        if (!((val += 0.1) >= 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
};

const serializeForm = (form) => {
    let field;
    const s = [];

    if (typeof form === 'object' && form.nodeName === 'FORM') {
        for (let i = 0; i < form.elements.length; i++) {
            field = form.elements[i];
            if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
                if (field.type === 'select-multiple') {
                    for (let j = form.elements[i].options.length - 1; j >= 0; j--) {
                        if (field.options[j].selected) {
                            s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[j].value);
                        }
                    }
                } else {
                    if ((field.type === 'checkbox') && field.checked) {
                        s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
                    } else {
                        if (field.type === 'radio' && field.checked) {
                            s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
                        } else {
                            if (field.type !== 'radio' && field.type !== 'checkbox') {
                                s[s.length] = encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value);
                            }
                        }
                    }
                }
            }
        }
    }
    return s.join('&').replace(/%20/g, '+');
};

const getFormData = ($formGroup, type) => {
    let formData = {};

    if (type === 'formData') {
        formData = new FormData();
    }

    const $singleInputs = $formGroup.querySelectorAll('input:not([type="checkbox"]):not([type="radio"]), select:not([multiple]), textarea');
    const $multipleInputs = $formGroup.querySelectorAll('input[type="checkbox"], select[multiple]');
    const $radioInputs = $formGroup.querySelectorAll('input[type="radio"]:checked');

    if (type === 'object') {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');

        if (page && (parseInt(page) > 1)) {
            formData.page = page;
        }
    }

    for (let s = 0; s < $singleInputs.length; s++) {
        const $field = $singleInputs[s];
        const fieldName = $field.getAttribute('name');
        const fieldValue = $field.value;
        let ignoreField = false;

        if (typeof $field.getAttribute('formdata') !== 'undefined' && $field.getAttribute('formdata') === 'ignore') {
            ignoreField = true;
        }

        if (fieldValue !== '' && ignoreField === false) {
            if (type === 'formData') {
                formData.append(fieldName, fieldValue);
            }

            if (type === 'object') {
                formData[fieldName] = fieldValue;
            }
        }
    }

    for (let m = 0; m < $multipleInputs.length; m++) {
        const $field = $multipleInputs[m];
        const fieldName = $field.getAttribute('name');
        let ignoreField = false;

        if (typeof $field.getAttribute('formdata') !== 'undefined' && $field.getAttribute('formdata') === 'ignore') {
            ignoreField = true;
        }

        if (ignoreField === false) {
            if ($field.getAttribute('multiple')) {
                const values = Array.from($field.querySelectorAll('option:checked'), e => e.value);
                for (let v = 0; v < values.length; v++) {
                    if (type === 'formData') {
                        formData.append(fieldName, values[v]);
                    }

                    if (type === 'object') {
                        formData[fieldName] = values[v];
                    }
                }
            } else {
                if ($field.checked === true && $field.value !== '') {
                    const fieldValue = $field.value;

                    if (type === 'formData') {
                        formData.append(fieldName, fieldValue);
                    }

                    if (type === 'object') {
                        if (!formData[fieldName]) {
                            formData[fieldName] = fieldValue;
                        } else {
                            formData[fieldName] += ',' + fieldValue;
                        }
                    }
                }
            }
        }
    }

    for (let r = 0; r < $radioInputs.length; r++) {
        const $field = $radioInputs[r];
        const fieldName = $field.getAttribute('name');
        let ignoreField = false;

        if (typeof $field.getAttribute('formdata') !== 'undefined' && $field.getAttribute('formdata') === 'ignore') {
            ignoreField = true;
        }

        if (ignoreField === false) {
            const fieldValue = $field.value;
            if (type === 'formData') {
                formData.append(fieldName, fieldValue);
            }

            if (type === 'object') {
                if (!formData[fieldName]) {
                    console.log('normal', formData[fieldName]);
                    formData[fieldName] = fieldValue;
                } else {
                    console.log('push', formData[fieldName]);
                    formData[fieldName].push(fieldValue);
                }
            }
        }
    }

    if (type === 'object') {
        formData.type = 'ajax';
    }

    return formData;
};

export {
    createElementFromHTML,
    getEntries,
    getParent,
    getOffset,
    loadImage,
    loadScript,
    // loadReCaptcha,
    loadYoutubeApi,
    loadGoogleApi,
    smoothScrollTo,
    containerSmoothScrollTo,
    tryParseJSON,
    uniqueID,
    fadeOut,
    fadeIn,
    serializeForm,
    getFormData
};
